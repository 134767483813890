import { Component, EventEmitter, OnInit } from '@angular/core';
import { AddAccountService } from './add-account.service';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { autocompleteSelectionValidator, PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';
import { MatDialog } from '@angular/material/dialog';
export let DATE_FORMATE = 'yyyy-MM-dd';
import * as intlTelInput from 'intl-tel-input';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CommonDataService } from 'app/common-data.service';

@Component({
    selector: 'jhi-add-account',
    templateUrl: './add-account.component.html',
    styleUrls: ['./add-account.component.css'],
    providers: [{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class AddAccountComponent implements OnInit {
    locationList: Array<any> = [];
    accountCode: string;
    accountManagerList: Array<any> = [];
    ARManagerList: Array<any> = [];
    actualCustomerGroupList: Array<any> = [];
    customerGroupList: Array<any> = [];
    isTimeTrackingAvailable: any = '1';
    selectedAccountManager: any;
    selectedARManager:any;
    selectedLocation: any;
    customerEntity: string;
	gspannEntityList: any;
    customerGroup: string;
    customerName: string;
    customerContactNumber: string;
    customerEmailId: String;
    customerReportingManager: string;
    accountForm: FormGroup;
    timeOut = null;
    resourcesList: any = [];
    filteredCountries: any = [];
    files: FileList;
    resumeLengthFlag = false;
    resumeSizeFlag = false;
    resumeName = [];
    resumeData = [];
    payloadResumeData = [];
    resumeNameList = [];
    requestPayload: any = {};
    countryControl = new FormControl();
	clicked = false;
    public reenableButton = new EventEmitter<boolean>(false);
    checked = false;
    datePipe = new DatePipe('en-US');
    selectedCountryCode: any;
    minEndDate = new Date();
    incorrectFileFormat: boolean;
    load=false;
    constructor(private addAccountService: AddAccountService, private router: Router, private fb: FormBuilder, private titleService: Title, public dialog: MatDialog, private commonService: CommonDataService) {
        this.titleService.setTitle('aperture - Add Account');
        this.accountForm = this.fb.group({
            customerName: new FormControl('', [Validators.required]),
            customerEntity: new FormControl('', [Validators.required]),
			gspannEntity: new FormControl('',[Validators.required]),
            customerReportingManager: new FormControl('', [Validators.required]),
            accountManager: new FormControl('', [Validators.required]),
            armanager: new FormControl('', [Validators.required]),
            customerEmailId: new FormControl('', Validators.compose([Validators.required])),
            customerContactNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
            country: ['', [Validators.required, autocompleteSelectionValidator]],
            timeTracking: new FormControl('', [Validators.required]),
            addMsaStartDate: new FormControl('', [Validators.required]),
            addMsaEndDate: new FormControl(''),
            customerGroup: new FormControl('', [Validators.required, Validators.minLength(0), Validators.maxLength(4)])
        });
    }

    ngOnInit() {
        this.addAccountService.getLocationAndAccountManagerList().subscribe(data => {
            this.locationList = data.locationsList;
            this.filteredCountries = data.locationsList;
            this.accountManagerList = data.accountManagerList;
            this.ARManagerList= data.ARManagerList;
            this.customerGroupList = data.customerGroupList;
            this.actualCustomerGroupList = data.customerGroupList;
			this.gspannEntityList = data.gspannEntityList;
			let entitiesSort = this.gspannEntityList.sort((c, d) => c.value.toLowerCase().localeCompare(d.value.toLowerCase()));
            let custGroupsort = this.customerGroupList.sort();
        });
        this.accountForm.get('timeTracking').setValue(this.isTimeTrackingAvailable);
        this.accountForm.get('country').setValue({ key: 'US', value: 'United States' });

        this.accountForm.get('country').valueChanges.subscribe(x => {
            if (x != null) {
                this.filteredCountries = this.locationList.filter(function(option) {
                    if (x.value) {
                        x = x.value;
                    }
                    let filterValue = x.toLowerCase();
                    return option.value.toLowerCase().includes(filterValue);
                });
            }
        });
        let resourceCode = sessionStorage.getItem('resourceCode');
        resourceCode = resourceCode.substring(0, 2);
        let input = document.querySelector('#phone');
        let iti = intlTelInput(input, {
            autoPlaceholder: 'off',
            initialCountry: resourceCode,
            separateDialCode: true,
            preferredCountries: ['IN', 'US']
        });
        input.addEventListener('countrychange', e => {
            this.selectedCountryCode = iti.getSelectedCountryData();
        });
    }

    displayFn(option) {
        if (option != null && option.value) {
            return option.value;
        } else {
            return option;
        }
    }

    public saveAccountDetails() {
        this.load = true;
		this.clicked = true;
		setTimeout(()=>this.clicked = false,2000);
        this.requestPayload['documents'] = [];
        if (this.payloadResumeData.length && this.resumeName.length) {
            for (let i = 0; i < this.payloadResumeData.length; i++) {
                this.requestPayload['documents'].push({ data: this.payloadResumeData[i], filename: this.resumeName[i] });
            }
        } else {
            this.requestPayload['documents'] = [];
        }
        let cPhoneNo = '';
        if (!this.selectedCountryCode) {
            if (sessionStorage.getItem('resourceCode').substring(0, 2) === 'IN') {
                cPhoneNo = sessionStorage.getItem('resourceCode').substring(0, 2) + '+91-' + this.customerContactNumber;
            } else {
                cPhoneNo = sessionStorage.getItem('resourceCode').substring(0, 2) + '+1-' + this.customerContactNumber;
            }
        } else {
            cPhoneNo = this.selectedCountryCode.iso2 + '+' + this.selectedCountryCode.dialCode + '-' + this.customerContactNumber;
        }
        let data = {
            accountManagerCode: this.commonService.encrypt(this.selectedAccountManager),
            arOwner:this.commonService.encrypt(this.selectedARManager),
            countryCode: this.accountForm.get('country').value.key,
            customerEntity: this.customerEntity,
			gspannEntity: this.accountForm.get('gspannEntity').value,
            customerName: this.customerName,
            customerEmailId: this.customerEmailId,
            customerContactNumber: cPhoneNo,
            customerGroup: this.customerGroup.toUpperCase(),
            customerReportingManager: this.customerReportingManager,
            mutualTermEndDate: this.datePipe.transform(this.accountForm.get('addMsaEndDate').value, DATE_FORMATE),
            mutualTermStartDate: this.datePipe.transform(this.accountForm.get('addMsaStartDate').value, DATE_FORMATE),
            mutualTerminationFlag: this.checked,
            customerTimeTracking: this.isTimeTrackingAvailable === '0' ? false : true,
            documents: this.requestPayload['documents']

        };
		this.reenableButton.emit(false);
        this.addAccountService.saveAccountDetails(data).subscribe(details => {
            setTimeout(() => {
                this.load = false;
            }, 1000);
            localStorage.setItem('previousUrl', 'createaccount');
            this.commonService.accountCode = details.accountCode;
            this.router.navigate(['view-account'],{skipLocationChange: true});
            (err=>{
                this.load = false;
            })
        });
    }

    searchCustomerGroup(query: string) {
        if (!query) {
            this.customerGroupList = this.actualCustomerGroupList;
            return;
        }
        let result = this.select(query, this.actualCustomerGroupList);
        this.customerGroupList = result;
    }
    validateNumber(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    searchResourceManager(searchValue) {
        if (searchValue) {
            this.accountForm.get('accountManager').setErrors({ 'incorrect': true });
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.addAccountService.searchResource(searchValue).subscribe(resourceList => {
                    this.resourcesList = resourceList['_embedded']['itrack:resourceListItemList'];
                    this.checkResourceAddAccountActive();
                    this.resourcesList.forEach((element, i) => {
                        this.addAccountService.getMethodRequest(element['_links']['image']['href']).subscribe((imgData: any) => {
                            this.resourcesList[i].image = imgData;
                        });
                    });
                });
            }, 1000);
        } else {
            this.resourcesList = [];
        }
    }
    checkResourceAddAccountActive() {
        this.resourcesList.forEach((element, i) => {
            if (element.emailId) {
                element.showResource = element.emailId.charAt(element.emailId.length - 1) === 'm';
            }
        });
    }
    searchResourceARmanager(searchValue) {
        if (searchValue) {
            this.accountForm.get('armanager').setErrors({ 'incorrect': true });
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.addAccountService.searchResource(searchValue).subscribe(resourceList => {
                    this.resourcesList = resourceList['_embedded']['itrack:resourceListItemList'];
                    this.checkResourceAddAccountActive();
                    this.resourcesList.forEach((element, i) => {
                        this.addAccountService.getMethodRequest(element['_links']['image']['href']).subscribe((imgData: any) => {
                            this.resourcesList[i].image = imgData;
                        });
                    });
                });
            }, 1000);
        } else {
            this.resourcesList = [];
        }
    }




    dateCheck(event) {
        let dd: Date = new Date();
        let local_flag = false;
        if (event <= dd) {
            local_flag = true;
        }
    }
    startDateChange(event) {
        this.minEndDate = event.value;
    }
    onResourceSelection(event) {
        this.accountForm.get('accountManager').setValue(event.option.value.name);
        this.selectedAccountManager = event.option.value.code;
    }
    onResourceSelectionAR(event) {
        this.accountForm.get('armanager').setValue(event.option.value.name);
        this.selectedARManager = event.option.value.code;
    }

    select(query: string, dataArray): string[] {
        let result: string[] = [];

        for (let list of dataArray) {
            if (list.value) {
                if (list.value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        return result;
    }

	openConfirmDialog(){
		let dialogRef = this.dialog.open(AddAccountDialog, {
			width: '400px',
			height: '110px',
		});

		dialogRef.afterClosed().subscribe(result=>{
			if (result){
			this.saveAccountDetails();
			}
		});
	}
    changeListener($event): void {
        this.files = $event.target.files;
        for (let i = 0; i < this.files.length; i++) {
            let file: File = this.files[i];
            if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/zip' || file.type === 'application/x-zip-compressed' || file.type === 'application/msword' || file.type === 'application/ms-doc' || file.type === 'application/doc' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||file.type === 'application/vnd.ms-excel' || file.type === 'application/excel' || file.type === 'application/x-excel' || file.type === 'application/x-msexcel') {
                this.incorrectFileFormat = false;
                // return;
            } else {
                this.incorrectFileFormat = true;
                return;
            }
            if (file && file.size >= 10024000) {
                this.resumeSizeFlag = true;
                return;
            } else {
                this.resumeSizeFlag = false;
            }
            if (file && file.name.length >= 100) {
                this.resumeLengthFlag = true;
                return;
            } else {
                this.resumeLengthFlag = false;
            }
            this.resumeName.push(file.name);
            this.resumeNameList.push(file.name);
            this.resumeData.push(file);
            let myReader: FileReader = new FileReader();
            myReader.onloadend = e => {
                this.resumeData[i] = myReader.result;
                this.payloadResumeData.push(myReader.result);
            };
            myReader.readAsDataURL(file);
        }
        this.resumeData = [];
    }
    removeResume(i: number): void {
        this.payloadResumeData.splice(i, 1);
        this.resumeNameList.splice(i, 1);
    }

    reset() {
        this.accountForm.reset({
            country: { key: 'US', value: 'United States' }
        });
        this.accountForm.get('country').valueChanges.subscribe(x => {
            this.filteredCountries = this.locationList.filter(function(option) {
                if (x.value) {
                    x = x.value;
                }
                let filterValue = x.toLowerCase();
                return option.value.toLowerCase().includes(filterValue);
            });
        });
        this.isTimeTrackingAvailable = '1';
        this.accountForm.get('timeTracking').setValue(this.isTimeTrackingAvailable);
        this.accountForm.get('customerGroup').setValue('');
        this.accountForm.get('customerGroup').setErrors(null);
        this.resumeName = [];
        this.resumeNameList = [];
        this.payloadResumeData = [];
        this.resumeLengthFlag = false;
        this.resumeSizeFlag = false;
        this.checked = false;
        this.accountForm.get('addMsaStartDate').reset();
        this.accountForm.get('addMsaEndDate').reset();
        this.resumeData =[];
    }

    checkstate(event) {
        this.checked = event.checked;
        if (this.checked) {
            this.accountForm.get('addMsaEndDate').clearValidators();
            this.accountForm.get('addMsaEndDate').reset();
            this.accountForm.get('addMsaEndDate').disable();
        } else {
            this.accountForm.get('addMsaEndDate').setValidators(Validators.required);
            this.accountForm.get('addMsaEndDate').enable();
        }
        this.accountForm.get('addMsaEndDate').updateValueAndValidity();
    }
}

@Component({
	selector: 'jhi-add-account-dialog',
	templateUrl: 'add-account-dialog.html',
  })
  export class AddAccountDialog {}
