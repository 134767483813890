import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environmentLMS, enviornmentAperture } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { salt, encIV } from 'app/app.constants'
// let environmentLMS.apiUrl = 'http://http://35.247.112.11:8005/aperture-leave';
@Injectable()
export class LeaveManagementService {
    resourcesCodeDTO: any;
    resourcesCodeForOnLoad: string;
    constructor(private http: HttpClient) {
    }
    
    encrypt(data: string): string {
        const ciphertext = CryptoJS.AES.encrypt(data, salt, {
            iv: encIV
        }).toString().trim();
        const replacedCipher = ciphertext.replace(/\//g, 'ForwardSlash').replace(/\+/g, 'PlusSign');
        return replacedCipher;
    }

    /* START - Holiday Calendar */
    getHolidaysbyYear(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/holidayCalendar/getHolidaysByYear');
    }
    getInitHolidayCalendar(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/holidayCalendar/initHolidayCalendar');
    }
    getHolidays(countryCode, year, locationId, cityName=''): Observable<any>{
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/holidayCalendar/getHolidays?countryCode='+countryCode+'&year='+year+'&locationId='+locationId);
    }
    getHolidaysByRegion(countryCode,year,locationId, cityName=''): Observable<any>{
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/holidayCalendar/getHolidays?countryCode='+countryCode+'&year='+year+'&locationId='+locationId+'&regionName='+cityName);
    }
    getInitAddorModify(): Observable<any> {
        return this.http.get(environmentLMS.apiUrl + '/api/v1/holidayCalendar/initAddOrModifyHolidays');
    }
    updateOnShoreLeaveCredit() {
        return this.http.get(`${environmentLMS.apiUrl}/onShoreLeaveCredit`);
    }

    updateOnShoreLeaveDebit() {
        return this.http.get(`${environmentLMS.apiUrl}/onShoreLeaveDebit`);
    }

    updateOffShoreLeaveCredit() {
        return this.http.get(`${environmentLMS.apiUrl}/offShoreLeaveCredit`);
    }
    updateOffShoreLeaveDebit() {
        return this.http.get(`${environmentLMS.apiUrl}/offShoreLeaveDebit`);
    }
    createHoliday(data): Observable<any> {
        return this.http.post(enviornmentAperture.apiUrl + 'api/v1/holidayCalendar/addHoliday', data, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    createOcassion(data): Observable<any> {
        return this.http.post(environmentLMS.apiUrl + '/api/v1/holidayCalendar/createOccasion', data, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    updateHoliday(data): Observable<any> {
        return this.http.put(enviornmentAperture.apiUrl + 'api/v1/holidayCalendar/updateHoliday', data, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    uploadBulkHoliday(data): Observable<any> {
        return this.http.post(environmentLMS.apiUrl + '/api/v1/holidayCalendar/uploadHoliday', data, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    deleteHoliday(id) {
        return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/holidayCalendar/deleteHoliday/' + id);
    }

    getLeaveBalance (resourceType, gender, resourceCode) {
        const encEmployeeType = this.encrypt(resourceType);
        const encGender = this.encrypt(gender);
        const encResourceCode = this.encrypt(resourceCode);

        return this.http.get(`${environmentLMS.apiUrl}/leave-balance?employeeType=${encEmployeeType}&gender=${encGender}&resourceCode=${encResourceCode}`);
    }

    requestLeave(payload, sendEmailAfterApply): Observable<any> {

      
        let formData = new FormData();
        let httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'multipart/form-data; charset=UTF-8'})
            };
            for (let key in payload.notifiedResourcesList) {
                formData.append(`notifiedResourcesList[${key}].resourceCode`,this.encrypt(payload.notifiedResourcesList[key].resourceCode));
                formData.append(`notifiedResourcesList[${key}].resourceEmailId`,payload.notifiedResourcesList[key].resourceEmailId);
                formData.append(`notifiedResourcesList[${key}].resourceName`,payload.notifiedResourcesList[key].resourceName);
            }
            for (let key in payload.holidayList) {
                formData.append(`holiDay.holidayDetails[${key}].dayOfWeek`,new Date(payload.holidayList[key]).getDay().toString());
                formData.append(`holiDay.holidayDetails[${key}].dayOfMonth`,new Date(payload.holidayList[key]).getDate().toString());
                formData.append(`holiDay.holidayDetails[${key}].year`,new Date(payload.holidayList[key]).getFullYear().toString());
                formData.append(`holiDay.holidayDetails[${key}].month`,new Date(payload.holidayList[key]).getMonth().toString());
            }
        formData.append('alternateContactNumber',payload.alternateContactNumber);
        formData.append('minutes',payload.minutes);
        formData.append('approverResourceCode',this.encrypt(payload.approverResourceCode));
        formData.append('approverResourceName',payload.approverResourceName);
        formData.append('reportingManagerEmail',payload.reportingManagerEmail);
        formData.append('reportingManagerName',payload.reportingManagerName);
        formData.append('deputedLocation',payload.deputedLocation);
        formData.append('resourceEmail',payload.resourceEmail);
        formData.append('leaveBalance',payload.leaveBalance);
        formData.append('payrollId',payload.payrollId);
        formData.append('days',payload.days);
        formData.append('employeeType',payload.employeeType);
        formData.append('fromDate',payload.fromDate);
        formData.append('leaveType',payload.leaveType);
        formData.append('numberOfHoursApplied',payload.numberOfHoursApplied);
        formData.append('reason',payload.reason);
        if (payload.routingFromViewResources) {
            formData.append('resourceCode',payload.resourceCode);
        } else {
            formData.append('resourceCode',this.encrypt(payload.resourceCode));
        }
        formData.append('resourceName',payload.resourceName);
        formData.append('toDate',payload.toDate);
        formData.append('daySession',payload.daySession);
        formData.append('leaveAppliedOnDate',payload.leaveAppliedOnDate);
        formData.append('dayType',payload.dayType);
        formData.append('leaveStatus',payload.leaveStatus);
        formData.append('weekendDay.weekEndDays',payload.weekendDays);
        formData.append('isApplyForOthers',payload.isApplyForOthers);
        formData.append('submitter', this.encrypt(payload.submitter));

        if (payload.file !== null) {
            formData.append('file',payload.file);
        }
        if (sendEmailAfterApply) {
            return this.http.patch(`${environmentLMS.apiUrl}/sendEmailAfterLeaveApplied`,formData, {}).pipe(map((response: HttpResponse<any>) =>   {
                return response;
            }));
        } else {
            return this.http.post(`${environmentLMS.apiUrl}/leave`,formData, {}).pipe(map((response: HttpResponse<any>) =>   {
                return response;
            }));
        }
    }

    getProjectDetailsByResourceCode(resourceCode, isCoded?):Observable<any> {
        if (!isCoded) {
            this.resourcesCodeDTO = this.encrypt(resourceCode)
        } else {
            this.resourcesCodeDTO = resourceCode
        }
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/projects/getProjectDetailsByResourceCode/${this.resourcesCodeDTO}`);
    }

    getOnLoadLeaveRequest(employeeType,gender, requestFromCompOff, resourceCode, isCoded?): Observable<any> {
        const encRequestCompOff = this.encrypt(requestFromCompOff.toString());
        if (!isCoded) {
            this.resourcesCodeForOnLoad = this.encrypt(resourceCode)
        } else {
            this.resourcesCodeForOnLoad = resourceCode
        }
        return this.http.get(`${environmentLMS.apiUrl}/comp-off/onLoadLeaveRequest?employeeType=${this.encrypt(employeeType)}&gender=${this.encrypt(gender)}&requestFromCompOff=${encRequestCompOff}&resourceCode=${this.resourcesCodeForOnLoad}`);
    }

    getAllResources(): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/resources/getAllResources`);
    }

    getActiveResources(searchParam): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + `api/v1/resources/searchActiveResources?searchParam=${searchParam}`);
    }

    requestCompOff(payload):Observable<any> {
        let formData = new FormData();
        for (let key in payload.compOffNotifiedResourcesList) {
            formData.append(`compOffNotifiedResourcesList[${key}].resourceCode`,this.encrypt(payload.compOffNotifiedResourcesList[key].resourceCode));
            formData.append(`compOffNotifiedResourcesList[${key}].resourceEmailId`,payload.compOffNotifiedResourcesList[key].resourceEmailId);
            formData.append(`compOffNotifiedResourcesList[${key}].resourceName`,payload.compOffNotifiedResourcesList[key].resourceName);
        }

        formData.append('approverResourceCode',this.encrypt(payload.approverResourceCode));
        formData.append('approverResourceName',payload.approverResourceName);
        formData.append('employeeType',payload.employeeType);
        formData.append('resourceEmail',payload.resourceEmail);
        formData.append('minutes',payload.minutes);
        formData.append('payrollId',payload.payrollId);
        formData.append('days',payload.days);
        formData.append('fromDate',payload.fromDate);
        formData.append('leaveType',payload.leaveType);
        formData.append('numberOfHoursApplied',payload.numberOfHoursApplied);
        formData.append('reason',payload.reason);
        formData.append('resourceCode',this.encrypt(payload.resourceCode));
        formData.append('resourceName',payload.resourceName);
        formData.append('toDate',payload.toDate);
        formData.append('leaveAppliedOnDate',payload.leaveAppliedOnDate);
        formData.append('leaveStatus',payload.leaveStatus);
        // formData.append('notifiedResources',payload.notifiedResources);
        formData.append('file',payload.file);
        formData.append('submitter', this.encrypt(payload.submitter));
        formData.append('submitterName',payload.submitterName);
        formData.append('projectCode',payload.projectCode);
        formData.append('projectName',payload.projectName);
        formData.append('reportingManagerEmail',payload.reportingManagerEmail);
        return this.http.post(`${environmentLMS.apiUrl}/comp-off/requestComp-Off`,formData,{}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    getMyLeaveHistory(fromDate, toDate, requestFromDashboard, resourceCode, employeeType): Observable<any> {
        const encResourceCode = this.encrypt(resourceCode);
        const encFromDate = this.encrypt(fromDate);
        const encEmployeeType = this.encrypt(employeeType);
        const encToDate = this.encrypt(toDate);
        const encSortByFromDate = this.encrypt('true');
        const encRequestFromDashboard = this.encrypt(requestFromDashboard.toString());

        return this.http.get(`${environmentLMS.apiUrl}/myLeaveHistory?employeeType=${encEmployeeType}&fromDate=${encFromDate}&requestFromDashboard=${encRequestFromDashboard}&resourceCode=${encResourceCode}&toDate=${encToDate}&sortByFromDate=${encSortByFromDate}`);
    }

    getMyTeamLeavesForReview(fromDate,toDate,resourceCode, page, pageSize, employeeType): Observable<any> {
        const encSortByFromDate = this.encrypt('true');
        return this.http.get(`${environmentLMS.apiUrl}/review/myTeamApplications?approverResourceCode=${this.encrypt(resourceCode)}&employeeType=${this.encrypt(employeeType)}&fromDate=${this.encrypt(fromDate)}&page=${this.encrypt(page)}&pageSize=${this.encrypt(pageSize)}&toDate=${this.encrypt(toDate)}&sortByFromDate=${encSortByFromDate}`);
    }

    getAllApprovedRejectedLeaves(resourceCode,page, pageSize, employeeType, sortByFromDate): Observable<any> {
        const encSortByFromDate = this.encrypt(sortByFromDate.toString());
        const encEmployeeType = this.encrypt(employeeType);
        const encPage = this.encrypt(page.toString())
        const encPageSize = this.encrypt(pageSize.toString());
        const encResourceCode = this.encrypt(resourceCode)

        return this.http.get(`${environmentLMS.apiUrl}/admin/revertLeaves?employeeType=${encEmployeeType}&page=${encPage}&pageSize=${encPageSize}&resourceCode=${encResourceCode}&sortByFromDate=${encSortByFromDate}`);
    }

    getMyGrantedLeaves(employeeType, resourceCode, sortByFromDate): Observable<any> {
        const encSortByFromDate = this.encrypt('true');
        return this.http.get(`${environmentLMS.apiUrl}/myGrantedLeaves?employeeType=${this.encrypt(employeeType)}&resourceCode=${this.encrypt(resourceCode)}&sortByFromDate=${encSortByFromDate}`);
    }    

    getUpcomingLeaves (requestFromDashboard, resourceCode) {
        return this.http.get(`${environmentLMS.apiUrl}/myLeaveHistory?requestFromDashboard=${this.encrypt(requestFromDashboard)}&resourceCode=${this.encrypt(resourceCode)}`);
    }

    getTeamLeaves (resourceCode, fromDate, toDate) {
        const encResourceCode = this.encrypt(resourceCode);
        const encFromDate = this.encrypt(fromDate);
        const encToDate = this.encrypt(toDate);
        return this.http.get(`${environmentLMS.apiUrl}/team-leave?fromDate=${encFromDate}&resourceCode=${encResourceCode}&toDate=${encToDate}`);
    }

    getLeaveDetailsFromID (leaveID,employeeType,gender) {
        return this.http.get(`${environmentLMS.apiUrl}/leave/${this.encrypt(leaveID.toString())}?employeeType=${this.encrypt(employeeType)}&gender=${this.encrypt(gender)}`);
    }

    downloadFile(id, leaveType): Observable<HttpResponse<Blob>> {
        const encId = this.encrypt(id.toString());
        const encLeaveType = this.encrypt(leaveType);
        return this.http.get<Blob>(`${environmentLMS.apiUrl}/documents/downloadFile/${encId}?leaveType=${encLeaveType}`,{
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    withdarwLeave(leaveType, id): Observable<any> {
        return this.http.patch(`${environmentLMS.apiUrl}/withdrawLeave?compOffLeave=${leaveType}&id=${id}`,null);
    }

    downloadFileHeader (id, leaveType): Observable<any> {
        const encLeaveType = this.encrypt(leaveType);
        const encId = this.encrypt(id.toString());
        return this.http.get(`${environmentLMS.apiUrl}/documents/downloadFileHeader/${encId}?leaveType=${encLeaveType}`);
    }

    approveLeave(data): Observable<any> {
        return this.http.patch(`${environmentLMS.apiUrl}/review/approveLeave` , data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    approveCompOffLeave(data): Observable<any> {
        return this.http.patch(`${environmentLMS.apiUrl}/review/approveCompOffLeave`, data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    rejectLeave(data): Observable<any> {
        return this.http.patch(`${environmentLMS.apiUrl}/review/rejectLeave`, data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    rejectCompOffLeave(data): Observable<any> {
        return this.http.patch(`${environmentLMS.apiUrl}/review/rejectCompOffLeave`, data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }
    getHolidaysByLeaveDateRange(fromDate, resourceCode, toDate): Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/getHolidaysAmongAppliedLeavesByResourceIdAndDateRange?fromDate=${this.encrypt(fromDate)}&resourceCode=${this.encrypt(resourceCode)}&toDate=${this.encrypt(toDate)}`);
    }

    getMyCompOffLeaves(id): Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/comp-off/leave/${this.encrypt(id.toString())}`);
    }
    leaveAction(data): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/updateLeave` , data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    bulkLeaveAction(payload): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/bulkUpdateLeave` , payload, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    grantCompOffApproval(data): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/grantedCompOffLeave` , data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    rejectCompOff(data): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/rejectCompOffLeave` , data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }
    
    getHolidaysByResourceID(resourceCode): Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/holiday/getHolidayByResourceCode?resourceCode=${this.encrypt(resourceCode)}`);
    }
    delegateLeave(data): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/delegateLeave` , data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    getTeamCompOffApplications(approverResourceCode,email, sortByFromDate): Observable<any> {
        const encSortByFromDate = this.encrypt(sortByFromDate.toString());
        return this.http.get(`${environmentLMS.apiUrl}/review/myTeamCompoffApplications?approverResourceCode=${this.encrypt(approverResourceCode)}&loggedInUserEmail=${this.encrypt(email)}&sortByFromDate=${encSortByFromDate}`);
    }

    getAdminCompOffApplications(sortByFromDate): Observable<any> {
        const encSortByFromDate = this.encrypt(sortByFromDate.toString());
        return this.http.get(`${environmentLMS.apiUrl}/admin/leave/getRequestedCompOffsForAdmin?sortByFromDate=${encSortByFromDate}`);
    }
    
    
    getCompletedLeaveApplications (resourceCode, fromDate, toDate, sortOption, pageNumber,pageSize) {
        const encSortByFromDate = this.encrypt('true');
        return this.http.get(`${environmentLMS.apiUrl}/review/getCompletedApplications?approverResourceCode=${this.encrypt(resourceCode)}&fromDate=${this.encrypt(fromDate)}&toDate=${this.encrypt(toDate)}&sortByFromDate=${encSortByFromDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    downloadFileFromCloud(fileName): Observable<any> {
        const encLeaveType = this.encrypt(fileName)
        return this.http.get(`${environmentLMS.apiUrl}/documents/downloadFileFromCloud?fileName=${encLeaveType}`,{ responseType: 'blob'});
    }

    getAdminExportData(url): Observable<any> {
        return this.http.get(url);
    }

    downloadAdminExportData(url): Observable<any> {
        return this.http.get(url, { responseType: 'blob',});
    }

    getAllLeaveApplicationsAdmin (fromDate, toDate, pageSize, page, employeeType) {
        const encSortByFromDate = this.encrypt('true');
        return this.http.get(`${environmentLMS.apiUrl}/admin/getAllLeaveApplications?employeeType=${this.encrypt(employeeType)}&fromDate=${this.encrypt(fromDate)}&toDate=${this.encrypt(toDate)}&pageSize=${this.encrypt(pageSize)}&page=${this.encrypt(page)}&sortByFromDate=${encSortByFromDate}`);
    }

    initCallAllLeaveApplicationsAdmin (employeeType, gender) {
        return this.http.get(`${environmentLMS.apiUrl}/admin/initAllLeaveApplications?employeeType=${this.encrypt(employeeType)}&genderType=${this.encrypt(gender)}`);
    }
    getDeputedLocations() {
        return this.http.get(`${enviornmentAperture.apiUrl}api/v1/locations/getDeputedLocations`);
    }

    getRequestedCompOffs(resourceCode, sortByFromDate): Observable<any> {
        const encSortByFromDate = this.encrypt(sortByFromDate.toString());
        return this.http.get(`${environmentLMS.apiUrl}/comp-off/leave/getRequestedCompOffs?resourceCode=${this.encrypt(resourceCode)}&sortByFromDate=${encSortByFromDate}`).pipe(map((response: HttpResponse<any>)=>{
            return response;
        }));
    }

    updateLeaves(financialYearStart, financialYearEnd, resourceCode,payload): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/updateLeaveBalance/${this.encrypt(resourceCode)}?financialYearEnd=${financialYearEnd}&financialYearStart=${financialYearStart}`, payload).pipe(map((response:HttpResponse<any>)=> {
            return response;
        }))
    }

    adjustLeave(payload): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/adjustLeaveBalance`, payload).pipe(map((response:HttpResponse<any>)=> {
            return response;
        }))
    }

    getFilteredLeaveHistory(payload): Observable<any> {
        const encSortByFromDate = this.encrypt('true');
        return this.http.get(`${environmentLMS.apiUrl}/searchMyAvailedLeaveHistory?resourceCode=${this.encrypt(payload.resourceCode)}&sortByFromDate=${encSortByFromDate}&fromDate=${this.encrypt(payload.fromDate)}&toDate=${this.encrypt(payload.toDate)}&leaveType=${this.encrypt(payload.leaveType)}&leaveStatus=${this.encrypt(payload.leaveStatus)}&employeeType=${this.encrypt(payload.employeeType)}`).pipe(map((response: HttpResponse<any>)=>{
            return response;
        }));
    }

    getInitLockedHistoryData(): Observable<any> {
        return this.http.get(environmentLMS.apiUrl + '/admin/getResourceLeaveLockedDate');
      }

    searchAllLeaveApplicationsAdmin (fromDate, toDate, pageSize, page, employeeType, leaveType, location, sortByFromDate, resourceCode) {
        return this.http.get(`${environmentLMS.apiUrl}/admin/searchAllLeaveApplications?employeeType=${this.encrypt('ALL')}&fromDate=${this.encrypt(fromDate)}&toDate=${this.encrypt(toDate)}&pageSize=${this.encrypt(pageSize.toString())}&page=${this.encrypt(page.toString())}&sortByFromDate=${this.encrypt(sortByFromDate.toString())}&leaveType=${this.encrypt(leaveType)}&location=${this.encrypt(location)}&resourceCode=${this.encrypt(resourceCode)}`);
    }

    searchAllReviewTeamApplications(approverResourceCode, employeeType,leaveType, page,pageSize, sortByFromDate, fromDate, toDate, email): Observable<any>{
        const encSortByFromDate = this.encrypt(sortByFromDate.toString());
        return this.http.get(`${environmentLMS.apiUrl}/review/searchMyTeamApplications?approverResourceCode=${this.encrypt(approverResourceCode)}&employeeType=${this.encrypt(employeeType)}&leaveType=${this.encrypt(leaveType)}&loggedInUserEmail=${this.encrypt(email)}&page=${this.encrypt(page.toString())}&pageSize=${this.encrypt(pageSize.toString())}`)

    }
    exportLeaveApprovalData(approverResourceCode,fromDate,toDate,pageSize,page,employeeType,leaveType,sortByFromDate): Observable<any> {
	    return this.http.put(`${environmentLMS.apiUrl}/exportApprovalData?approverResourceCode=${approverResourceCode}&fromDate=${fromDate}&toDate=${toDate}&pageSize=${pageSize}&page=${page}&sortByFromDate=${sortByFromDate}&leaveType=${leaveType}&&employeeType=${employeeType}`, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }
    
    searchAvailedLeaves(employeeType, fromDate, leaveType, leaveStatus, resourceCode, sortByFromDate,toDate): Observable<any> {
        const encSortByFromDate = this.encrypt(sortByFromDate.toString());
        return this.http.get(`${environmentLMS.apiUrl}/searchMyAvailedLeaveHistory?employeeType=${this.encrypt(employeeType)}&fromDate=${this.encrypt(fromDate)}&leaveStatus=${this.encrypt(leaveStatus)}&leaveType=${this.encrypt(leaveType)}&resourceCode=${this.encrypt(resourceCode)}&sortByFromDate=${encSortByFromDate}&toDate=${this.encrypt(toDate)}`);
    }

    expireCompOff(resourceCode): Observable<any> {
        return this.http.put(`${environmentLMS.apiUrl}/comp-off/expireCompOff?resourceCode=${this.encrypt(resourceCode)}`, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }
    uploadLeaveData(payload): Observable<any> {
        let formData = new FormData();
        let httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'multipart/form-data; charset=UTF-8'})
            };
            
        if (payload.file !== null) {
            formData.append('file',payload.file);
        }
       return this.http.post(`${environmentLMS.apiUrl}/leave/upload/excel`,formData, {}).pipe(map((response: HttpResponse<any>) =>   {
            return response;
        }));
    }

    uploadAnnualLeaveData(payload): Observable<any> {
        let formData = new FormData();
        let httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'multipart/form-data; charset=UTF-8'})
            };
            
        if (payload.file !== null) {
            formData.append('file',payload.file);
        }
       return this.http.post(`${environmentLMS.apiUrl}/leave/uploadAnnualLeave/excel`,formData, {}).pipe(map((response: HttpResponse<any>) =>   {
            return response;
        }));
    }

    uploadBulkLeaveData(payload): Observable<any> {
        let formData = new FormData();
        let httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'multipart/form-data; charset=UTF-8'})
            };
            
        if (payload.file !== null) {
            formData.append('file',payload.file);
        }
       return this.http.post(`${environmentLMS.apiUrl}/leave/bulkLeaveAdjustmentThroughExcel`,formData, {}).pipe(map((response: HttpResponse<any>) =>   {
            return response;
        }));
    }

    exportMonthLeaveData(fromDate, toDate, leaveType,companyEntity): Observable<any> {
        let construct_uri;
        if (leaveType !== null && leaveType !== '' && leaveType !== undefined) {
            construct_uri = `/admin/daywiseLeaveReportToExport?companyEntity=${this.encrypt(companyEntity)}&fromDate=${this.encrypt(fromDate)}&leaveType=${this.encrypt(leaveType)}&toDate=${this.encrypt(toDate)}`;
        } else {
            construct_uri = `/admin/daywiseLeaveReportToExport?companyEntity=${this.encrypt(companyEntity)}&fromDate=${this.encrypt(fromDate)}&toDate=${this.encrypt(toDate)}`;
        }
        
        return this.http.get(`${environmentLMS.apiUrl}${construct_uri}`,{ responseType: 'blob'})
    }

    updateLockDates(payload): Observable<any> {
        console.log("payload", payload);
        return this.http.put(`${environmentLMS.apiUrl}/admin/updateLockDates?resourceType=${this.encrypt(payload.resourceType)}&offshoreLockedDate=${this.encrypt(payload.lockDate)}&lockedByName=${this.encrypt(payload.lockedByName)}`, {}).pipe(map((response:HttpResponse<any>)=> {
            return response;
        }))
    }

    exportLOPReport(fromDate, toDate, companyEntity) : Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/admin/lossOfPayDataToExport?companyEntity=${this.encrypt(companyEntity)}&fromDate=${this.encrypt(fromDate)}&toDate=${this.encrypt(toDate)}`,{responseType: 'blob'});
    }

    exportMyLeaveHistory(fromDate,toDate): Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/exportMyLeaveHistoryToExcel?fromDate=${fromDate}&toDate=${(toDate)}`,{responseType:'blob'});
    }
    exportLeaveEncashmentReport(fromDate, toDate, companyEntity): Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/admin/leaveEncashmentDataToExport?companyEntity=${this.encrypt(companyEntity)}&fromDate=${this.encrypt(fromDate)}&toDate=${this.encrypt(toDate)}`,{responseType: 'blob'});
    }

    exportLeaveAdjustmentReport(): Observable<any> {
        // exportDataForLeaveAdjustment
        return this.http.get(`${environmentLMS.apiUrl}/exportDataForLeaveAdjustment`,{responseType: 'blob'});
    }

    exportleaveTransactionReport(fromDate, toDate, leaveType,companyEntity): Observable<any> {
        let construct_uri;
        if (leaveType !== null && leaveType !== '' && leaveType !== undefined) {
            construct_uri = `/admin/leaveTransactinReportToExport?companyEntity=${this.encrypt(companyEntity)}&fromDate=${this.encrypt(fromDate)}&leaveType=${this.encrypt(leaveType)}&toDate=${this.encrypt(toDate)}`;
        } else {
            construct_uri = `/admin/leaveTransactinReportToExport?companyEntity=${this.encrypt(companyEntity)}&fromDate=${this.encrypt(fromDate)}&toDate=${this.encrypt(toDate)}`;
        }
        return this.http.get(`${environmentLMS.apiUrl}${construct_uri}`,{responseType: 'blob'});
    }

    getAllLeaveTypes(): Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/admin/AllLeaveTypes`);
    }

    exportLeaveBalanceReport(leaveType, companyEntity): Observable<any> {
        return this.http.get(`${environmentLMS.apiUrl}/admin/leavebalanceToExport?companyEntity=${this.encrypt(companyEntity)}&leaveType=${this.encrypt(leaveType)}`,{responseType: 'blob'});
    }
}
